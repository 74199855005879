<template>
  <div class="text-left">
    <h4 class="text-center">
      Termos de Responsabilidade
    </h4>
    <p>
      Amet facilisis purus aliquam feugiat in himenaeos mauris justo, gravida
      vestibulum nullam massa lobortis scelerisque habitant eget cubilia, netus
      sapien taciti senectus convallis interdum blandit. platea vel gravida
      lacinia fusce sem tincidunt cursus luctus curae lobortis aliquam pharetra
      blandit, sem aenean inceptos rutrum justo donec facilisis eget massa porta
      donec tristique. viverra curabitur varius quisque dictum conubia nunc
      lectus primis bibendum ornare, ut netus rutrum laoreet congue convallis
      donec lobortis convallis, lectus id at hendrerit cursus sodales sociosqu
      proin mollis. enim auctor curabitur proin torquent sapien convallis
      euismod, etiam fusce sodales lobortis tellus fusce, neque egestas nulla
      aliquam congue eros.
    </p>
    <p>
      Congue curae et fames ornare orci dictumst in vehicula aenean accumsan,
      arcu nullam nisi platea arcu maecenas volutpat massa est convallis, eget
      nulla donec iaculis donec ullamcorper rutrum est porta. turpis sem
      sagittis pharetra porta urna iaculis lobortis class, potenti taciti lectus
      pharetra proin nisl dictumst, curabitur scelerisque ligula praesent erat
      inceptos etiam. malesuada maecenas consequat ac libero sagittis eget
      purus, elementum pharetra fames dictum sagittis ligula mollis elementum,
      non massa proin molestie fringilla urna. nulla duis consectetur tempor
      commodo curabitur porttitor mollis elementum, dictum taciti tortor
      vehicula tortor quis consectetur, semper non aliquam purus quis
      condimentum adipiscing.
    </p>
    <p>
      Pellentesque eget erat lectus tortor aenean proin at vehicula, platea
      aliquam lectus ut nisi sodales pellentesque sagittis porta, lorem potenti
      tortor aliquet purus nulla erat. leo tristique ipsum vivamus fermentum
      platea, quisque lobortis iaculis odio non a, dictumst semper lacus ligula.
      curae quis nibh sodales vel dictum risus sagittis purus platea mauris,
      sociosqu dictum aliquam tempor vehicula sem mi et eros, pulvinar a per
      aliquet praesent litora potenti diam sollicitudin. habitasse habitant
      ullamcorper ut egestas ut turpis molestie metus lacus vitae auctor,
      eleifend consectetur et magna nam fringilla enim adipiscing egestas
      faucibus sociosqu, sed ad quisque malesuada pellentesque posuere nibh
      egestas suspendisse quam.
    </p>
    <p>
      Ante ligula libero luctus venenatis hac adipiscing fringilla ad, etiam
      porta rhoncus torquent non urna nam ipsum, praesent vestibulum dictum
      condimentum at porttitor vestibulum. purus duis platea rhoncus mattis
      malesuada bibendum imperdiet aenean morbi, phasellus rhoncus mauris
      suspendisse aliquam nisi habitant potenti vivamus, sapien vitae dapibus
      lorem sem scelerisque ligula mauris. hac vestibulum lectus sem enim
      egestas habitasse blandit hac, nam ante aenean nisl ad varius iaculis,
      euismod libero feugiat mauris eros blandit justo. nunc cubilia mollis
      varius class lacus pellentesque sollicitudin vitae tellus, nam phasellus
      aliquam netus et urna at lorem tincidunt, pellentesque consectetur hac
      eget tempor nec pharetra dapibus.
    </p>
    <p>
      Felis eros fringilla turpis facilisis primis integer lobortis libero
      justo, elementum feugiat congue phasellus ultrices massa suscipit
      suspendisse, dictumst dui feugiat mauris risus vehicula senectus inceptos.
      nostra donec etiam risus tortor viverra laoreet mattis vivamus nec ligula
      senectus dolor, sit cubilia arcu est egestas metus ultricies lacinia
      condimentum fames sem. sagittis senectus commodo lacinia ante dictum
      praesent iaculis risus nam himenaeos, proin donec ac curae nec curabitur
      magna enim scelerisque euismod faucibus, donec fermentum adipiscing aptent
      nisi rhoncus aptent elementum nullam ligula, gravida nulla vestibulum
      euismod pulvinar consequat aliquam purus quisque.
    </p>
    <p>
      Imperdiet magna lacinia nibh fermentum rutrum sodales, sit scelerisque
      platea turpis aenean mauris mollis, sem pharetra platea conubia fermentum.
      venenatis fusce etiam ipsum sed hendrerit luctus, per ultrices laoreet
      bibendum maecenas imperdiet, habitasse accumsan tellus vestibulum litora.
      pellentesque vel aenean at vehicula ultricies vestibulum mauris, mattis
      vulputate conubia lacinia libero maecenas aliquet, at augue mauris mattis
      duis justo. porttitor quis mi congue commodo iaculis metus senectus risus
      nec interdum, turpis hac placerat posuere neque nisl laoreet vestibulum
      suspendisse, fusce aliquam imperdiet dui quam id venenatis libero
      faucibus. magna habitasse pharetra netus euismod congue tincidunt blandit,
      aliquam porttitor tincidunt tempus nisi suspendisse, quam consectetur eu
      integer in iaculis.
    </p>
    <p>
      Vulputate ac porttitor vulputate dui aenean nisl libero leo vel id, etiam
      molestie maecenas sociosqu lectus ultrices imperdiet ut facilisis ante,
      vehicula torquent eleifend taciti enim etiam cubilia euismod nec. mollis
      at dui nec maecenas lorem, praesent molestie laoreet vulputate lacinia,
      mollis mi nullam per. tortor litora ut nibh proin tempor ultricies
      convallis sociosqu, egestas libero nam senectus arcu massa elit curabitur
      interdum, etiam quam quis proin vehicula a viverra. mi augue diam proin
      amet porttitor ac vivamus, sodales lobortis nibh congue urna nunc vitae
      nullam, leo curabitur consectetur nam massa venenatis.
    </p>
    <p>
      Odio luctus fermentum porttitor nec inceptos consectetur vehicula nam
      commodo duis, tempus nam neque dictumst enim mattis iaculis taciti in,
      sapien sed neque suscipit ad vel mi hendrerit nulla. pharetra aenean
      lacinia litora viverra ut auctor sit torquent maecenas sagittis, semper
      accumsan congue sagittis nisl sodales a litora. morbi curae adipiscing a
      ad elit, quis nam porta. tempor ornare class phasellus vehicula dictum
      amet imperdiet, dictum lacus vivamus quam platea malesuada, lorem sed diam
      pretium ut curabitur. taciti amet inceptos ornare consectetur molestie
      aenean etiam elit tortor dictum, varius metus diam tincidunt commodo
      turpis primis elementum risus, curae felis eget condimentum donec iaculis
      rhoncus varius augue.
    </p>
    <p>
      Luctus posuere fames torquent massa nibh inceptos mauris id platea, nisi
      nisl turpis faucibus a curabitur curae dui velit turpis, mauris
      pellentesque dui pellentesque felis eget etiam porttitor. ullamcorper
      himenaeos nisl quisque gravida rhoncus id lectus cursus felis libero
      nostra, interdum neque class hac fames tristique adipiscing vehicula
      sagittis sem. porta laoreet sit luctus venenatis donec cursus fusce, arcu
      metus netus sollicitudin commodo euismod, vivamus facilisis ad nam quisque
      ipsum. nunc aptent class aliquam consequat dolor convallis a rhoncus,
      ligula platea per sapien a habitant varius velit, fringilla lorem inceptos
      cubilia feugiat vulputate sem.
    </p>
    <p>
      Congue tincidunt accumsan est scelerisque tristique nec mi, auctor vitae
      massa lobortis porttitor volutpat, nostra vulputate erat ultricies amet
      scelerisque vel, tempor cursus risus luctus bibendum. iaculis porta
      consequat etiam blandit vulputate quam accumsan eget posuere, congue
      aliquam dapibus phasellus varius netus sapien adipiscing luctus, ut aptent
      ligula sem sapien cursus fusce imperdiet. etiam donec varius accumsan
      risus fusce risus sit dui, urna rutrum cras sed arcu iaculis enim
      bibendum, sit malesuada interdum a sagittis hendrerit ornare. maecenas
      nulla potenti interdum conubia iaculis habitant mattis ultricies tortor,
      sed suscipit luctus dapibus laoreet a faucibus leo curabitur, massa duis
      in neque dolor litora in amet.
    </p>
    <p>
      Amet facilisis purus aliquam feugiat in himenaeos mauris justo, gravida
      vestibulum nullam massa lobortis scelerisque habitant eget cubilia, netus
      sapien taciti senectus convallis interdum blandit. platea vel gravida
      lacinia fusce sem tincidunt cursus luctus curae lobortis aliquam pharetra
      blandit, sem aenean inceptos rutrum justo donec facilisis eget massa porta
      donec tristique. viverra curabitur varius quisque dictum conubia nunc
      lectus primis bibendum ornare, ut netus rutrum laoreet congue convallis
      donec lobortis convallis, lectus id at hendrerit cursus sodales sociosqu
      proin mollis. enim auctor curabitur proin torquent sapien convallis
      euismod, etiam fusce sodales lobortis tellus fusce, neque egestas nulla
      aliquam congue eros.
    </p>
    <p>
      Congue curae et fames ornare orci dictumst in vehicula aenean accumsan,
      arcu nullam nisi platea arcu maecenas volutpat massa est convallis, eget
      nulla donec iaculis donec ullamcorper rutrum est porta. turpis sem
      sagittis pharetra porta urna iaculis lobortis class, potenti taciti lectus
      pharetra proin nisl dictumst, curabitur scelerisque ligula praesent erat
      inceptos etiam. malesuada maecenas consequat ac libero sagittis eget
      purus, elementum pharetra fames dictum sagittis ligula mollis elementum,
      non massa proin molestie fringilla urna. nulla duis consectetur tempor
      commodo curabitur porttitor mollis elementum, dictum taciti tortor
      vehicula tortor quis consectetur, semper non aliquam purus quis
      condimentum adipiscing.
    </p>
    <p>
      Pellentesque eget erat lectus tortor aenean proin at vehicula, platea
      aliquam lectus ut nisi sodales pellentesque sagittis porta, lorem potenti
      tortor aliquet purus nulla erat. leo tristique ipsum vivamus fermentum
      platea, quisque lobortis iaculis odio non a, dictumst semper lacus ligula.
      curae quis nibh sodales vel dictum risus sagittis purus platea mauris,
      sociosqu dictum aliquam tempor vehicula sem mi et eros, pulvinar a per
      aliquet praesent litora potenti diam sollicitudin. habitasse habitant
      ullamcorper ut egestas ut turpis molestie metus lacus vitae auctor,
      eleifend consectetur et magna nam fringilla enim adipiscing egestas
      faucibus sociosqu, sed ad quisque malesuada pellentesque posuere nibh
      egestas suspendisse quam.
    </p>
    <p>
      Ante ligula libero luctus venenatis hac adipiscing fringilla ad, etiam
      porta rhoncus torquent non urna nam ipsum, praesent vestibulum dictum
      condimentum at porttitor vestibulum. purus duis platea rhoncus mattis
      malesuada bibendum imperdiet aenean morbi, phasellus rhoncus mauris
      suspendisse aliquam nisi habitant potenti vivamus, sapien vitae dapibus
      lorem sem scelerisque ligula mauris. hac vestibulum lectus sem enim
      egestas habitasse blandit hac, nam ante aenean nisl ad varius iaculis,
      euismod libero feugiat mauris eros blandit justo. nunc cubilia mollis
      varius class lacus pellentesque sollicitudin vitae tellus, nam phasellus
      aliquam netus et urna at lorem tincidunt, pellentesque consectetur hac
      eget tempor nec pharetra dapibus.
    </p>
    <p>
      Felis eros fringilla turpis facilisis primis integer lobortis libero
      justo, elementum feugiat congue phasellus ultrices massa suscipit
      suspendisse, dictumst dui feugiat mauris risus vehicula senectus inceptos.
      nostra donec etiam risus tortor viverra laoreet mattis vivamus nec ligula
      senectus dolor, sit cubilia arcu est egestas metus ultricies lacinia
      condimentum fames sem. sagittis senectus commodo lacinia ante dictum
      praesent iaculis risus nam himenaeos, proin donec ac curae nec curabitur
      magna enim scelerisque euismod faucibus, donec fermentum adipiscing aptent
      nisi rhoncus aptent elementum nullam ligula, gravida nulla vestibulum
      euismod pulvinar consequat aliquam purus quisque.
    </p>
    <p>
      Imperdiet magna lacinia nibh fermentum rutrum sodales, sit scelerisque
      platea turpis aenean mauris mollis, sem pharetra platea conubia fermentum.
      venenatis fusce etiam ipsum sed hendrerit luctus, per ultrices laoreet
      bibendum maecenas imperdiet, habitasse accumsan tellus vestibulum litora.
      pellentesque vel aenean at vehicula ultricies vestibulum mauris, mattis
      vulputate conubia lacinia libero maecenas aliquet, at augue mauris mattis
      duis justo. porttitor quis mi congue commodo iaculis metus senectus risus
      nec interdum, turpis hac placerat posuere neque nisl laoreet vestibulum
      suspendisse, fusce aliquam imperdiet dui quam id venenatis libero
      faucibus. magna habitasse pharetra netus euismod congue tincidunt blandit,
      aliquam porttitor tincidunt tempus nisi suspendisse, quam consectetur eu
      integer in iaculis.
    </p>
    <p>
      Vulputate ac porttitor vulputate dui aenean nisl libero leo vel id, etiam
      molestie maecenas sociosqu lectus ultrices imperdiet ut facilisis ante,
      vehicula torquent eleifend taciti enim etiam cubilia euismod nec. mollis
      at dui nec maecenas lorem, praesent molestie laoreet vulputate lacinia,
      mollis mi nullam per. tortor litora ut nibh proin tempor ultricies
      convallis sociosqu, egestas libero nam senectus arcu massa elit curabitur
      interdum, etiam quam quis proin vehicula a viverra. mi augue diam proin
      amet porttitor ac vivamus, sodales lobortis nibh congue urna nunc vitae
      nullam, leo curabitur consectetur nam massa venenatis.
    </p>
    <p>
      Odio luctus fermentum porttitor nec inceptos consectetur vehicula nam
      commodo duis, tempus nam neque dictumst enim mattis iaculis taciti in,
      sapien sed neque suscipit ad vel mi hendrerit nulla. pharetra aenean
      lacinia litora viverra ut auctor sit torquent maecenas sagittis, semper
      accumsan congue sagittis nisl sodales a litora. morbi curae adipiscing a
      ad elit, quis nam porta. tempor ornare class phasellus vehicula dictum
      amet imperdiet, dictum lacus vivamus quam platea malesuada, lorem sed diam
      pretium ut curabitur. taciti amet inceptos ornare consectetur molestie
      aenean etiam elit tortor dictum, varius metus diam tincidunt commodo
      turpis primis elementum risus, curae felis eget condimentum donec iaculis
      rhoncus varius augue.
    </p>
    <p>
      Luctus posuere fames torquent massa nibh inceptos mauris id platea, nisi
      nisl turpis faucibus a curabitur curae dui velit turpis, mauris
      pellentesque dui pellentesque felis eget etiam porttitor. ullamcorper
      himenaeos nisl quisque gravida rhoncus id lectus cursus felis libero
      nostra, interdum neque class hac fames tristique adipiscing vehicula
      sagittis sem. porta laoreet sit luctus venenatis donec cursus fusce, arcu
      metus netus sollicitudin commodo euismod, vivamus facilisis ad nam quisque
      ipsum. nunc aptent class aliquam consequat dolor convallis a rhoncus,
      ligula platea per sapien a habitant varius velit, fringilla lorem inceptos
      cubilia feugiat vulputate sem.
    </p>
    <p>
      Congue tincidunt accumsan est scelerisque tristique nec mi, auctor vitae
      massa lobortis porttitor volutpat, nostra vulputate erat ultricies amet
      scelerisque vel, tempor cursus risus luctus bibendum. iaculis porta
      consequat etiam blandit vulputate quam accumsan eget posuere, congue
      aliquam dapibus phasellus varius netus sapien adipiscing luctus, ut aptent
      ligula sem sapien cursus fusce imperdiet. etiam donec varius accumsan
      risus fusce risus sit dui, urna rutrum cras sed arcu iaculis enim
      bibendum, sit malesuada interdum a sagittis hendrerit ornare. maecenas
      nulla potenti interdum conubia iaculis habitant mattis ultricies tortor,
      sed suscipit luctus dapibus laoreet a faucibus leo curabitur, massa duis
      in neque dolor litora in amet.
    </p>
    <p>
      Aptent commodo erat, nam.
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {},
};
</script>
