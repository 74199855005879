import HTTPService from "@/services/http_service.js";

export default class AdministrativoService extends HTTPService {
  static build() {
    return new AdministrativoService();
  }

  /**
   * @param {Number} page
   * @param {Number} per_page
   * @param {String} search
   * @param {String} order
   * @param {String} desc
   * @returns {Promise<{pagination: {numRows,currentPage,firstPage,lastPage,previousPage,nextPage,perPage}, administrativos: {estado, cidade, ativo, tipo, numero, referencias, administrativoId, cep, idClinica, complemento, nomeFantasia, idPerfil, descricaoPerfil, genero, cpf, dataNascimento, email, telefone1, bairro, profissao, nome, estadoCivil, telefone2, foto, rg, logradouro}[]}>}
   */
  async getUnidadePaginatedList({
    page,
    per_page,
    search,
    order,
    desc,
    unidade,
  }) {
    try {
      const response = await this.get("administrativo", {
        page,
        per_page,
        nome: search,
        order,
        desc,
        unidade,
      });
      if (response.status !== 200)
        return { administrativos: [], pagination: {} };
      const { data } = await response.json();
      let { pagination, administrativos } = data;
      administrativos = (administrativos || []).map((administrativo) => ({
        administrativoId: ~~administrativo.id,
        cpf: administrativo.cpf || "",
        rg: administrativo.rg || "",
        nome: administrativo.nome || "",
        genero: administrativo.genero || "",
        dataNascimento: administrativo.data_nascimento || "",
        telefone1: administrativo.telefone_1 || "",
        telefone2: administrativo.telefone_2 || "",
        cep: administrativo.cep || "",
        numero: administrativo.numero || "",
        complemento: administrativo.complemento || "",
        referencias: administrativo.referencias || "",
        estado: administrativo.estado || "",
        cidade: administrativo.cidade || "",
        bairro: administrativo.bairro || "",
        logradouro: administrativo.logradouro || "",
        email: administrativo.email || "",
        foto: administrativo.foto || "",
        profissao: administrativo.profissao || "",
        estadoCivil: administrativo.estado_civil || "",
        ativo: administrativo.ativo,
        tipo: administrativo.tipo || "",
        idClinica: ~~administrativo.id_clinica,
        nomeFantasia: administrativo.nome_fantasia || "",
        idPerfil: ~~administrativo.id_perfil || "",
        descricaoPerfil: administrativo.descricao_perfil || "",
        idUsuario: administrativo.id_usuario || "",
      }));
      pagination = {
        numRows: ~~pagination.num_rows,
        currentPage: ~~pagination.current_page,
        firstPage: ~~pagination.first_page,
        lastPage: ~~pagination.last_page,
        previousPage: ~~pagination.previous_page,
        nextPage: ~~pagination.next_page,
        perPage: ~~pagination.per_page,
      };
      return { administrativos, pagination };
    } catch (e) {
      return { administrativos: [], pagination: {} };
    }
  }

  async cadastrarAdministrativo({
    ativo,
    nome,
    email,
    rg,
    genero,
    data_nascimento,
    profissao,
    estado_civil,
    cep,
    numero,
    complemento,
    referencias,
    cpf,
    telefone_1,
    telefone_2,
    id_perfil,
    bairro,
    cidade,
    estado,
    logradouro,
    senha,
    id_clinica,
  }) {
    try {
      const body = {
        ativo,
        nome,
        email,
        rg,
        genero,
        data_nascimento,
        profissao,
        estado_civil,
        cep,
        numero,
        complemento,
        referencias,
        cpf,
        telefone_1,
        telefone_2,
        id_perfil,
        bairro,
        cidade,
        estado,
        logradouro,
        senha,
        id_clinica,
      };
      const response = await this.post("administrativo", body);
      const { data, errors } = await response.json();
      if (response.status === 400 && errors) {
        return errors;
      }
      return ~~(data || {}).id_administrativo;
    } catch (e) {
      return 0;
    }
  }

  /**
   * @param {Number} administrativoId
   * @returns {Promise<{estado, cidade, ativo: (number|*), tipo: (string|null|default.watch.tipo|*), numero, referencias, administrativoId, cep, idClinica: (number|*), complemento, nomeFantasia: (string|*), idPerfil: (default.methods.perfilId|*), descricaoPerfil: *, genero, cpf, dataNascimento, email, telefone1, bairro, profissao: (string|*), nome, estadoCivil: (default.methods.estadoCivil|default.methods.estadoCivil|*), telefone2, foto: (string|null|*), rg, logradouro}>}
   */
  async getDetalhesByAdministrativoId(administrativoId) {
    try {
      const response = await this.get("administrativo/" + administrativoId);
      if (response.status !== 200) return {};
      const { data } = await response.json();
      let id_clinicas = [];
      data.id_clinica.map((clinica) => {
        id_clinicas.push(parseInt(clinica.id_unidade));
      });
      return {
        administrativoId: ~~data.id,
        cpf: data.cpf,
        rg: data.rg,
        nome: data.nome,
        genero: data.genero,
        dataNascimento: data.data_nascimento,
        telefone1: data.telefone_1,
        telefone2: data.telefone_2,
        cep: data.cep,
        numero: data.numero,
        complemento: data.complemento,
        referencias: data.referencias,
        estado: data.estado,
        cidade: data.cidade,
        bairro: data.bairro,
        logradouro: data.logradouro,
        email: data.email,
        foto: data.foto,
        profissao: data.profissao,
        estadoCivil: data.estado_civil,
        ativo: data.ativo,
        tipo: data.tipo,
        // idClinica: ~~data.id_clinica,
        idClinica: id_clinicas,
        nomeFantasia: data.nome_fantasia,
        idPerfil: ~~data.id_perfil,
        descricaoPerfil: data.descricao_perfil,
      };
    } catch (e) {
      return { administrativos: [], pagination: {} };
    }
  }

  async atualizarAdministrativo(
    administrativoId,
    {
      ativo,
      nome,
      email,
      rg,
      genero,
      data_nascimento,
      profissao,
      estado_civil,
      cep,
      numero,
      complemento,
      referencias,
      cpf,
      telefone_1,
      telefone_2,
      id_perfil,
      bairro,
      cidade,
      estado,
      logradouro,
      senha,
      id_clinica,
    }
  ) {
    try {
      const body = {
        ativo,
        nome,
        email,
        rg,
        genero,
        data_nascimento,
        profissao,
        estado_civil,
        cep,
        numero,
        complemento,
        referencias,
        cpf,
        telefone_1,
        telefone_2,
        id_perfil,
        bairro,
        cidade,
        estado,
        logradouro,
        senha,
        id_clinica,
      };
      const response = await this.post(
        "administrativo/" + administrativoId,
        body
      );
      const { errors } = await response.json();
      if (response.status === 400 && errors) {
        return errors;
      }
      return true;
    } catch (e) {
      return 0;
    }
  }
}
