<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Fila TeleVet
        </h3>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-12 d-flex justify-content-center">
        <v-select
          class="col-3 mt-4"
          label="Especialidades"
          style="padding-top: 0 !important"
          :items="listaEspecialidades"
          v-model="especialidadeId"
        />
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="col-5"
              v-model="date"
              label="Data"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            color="#1daf80"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
        <v-btn
          class="ml-2 mt-3"
          outlined
          color="#1daf80"
          :disabled="disablePesquisa"
          @click="pesquisaDisponibilidade"
        >
          buscar
          <v-icon class="ml-2" small>
            mdi-magnify
          </v-icon>
        </v-btn>
      </div> -->
    </div>
    <div class="row">
      <div class="col-12">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :search="search"
          :headers="headers"
          :items="listaTeleconsultas"
          class="elevation-1"
          :loading="loading"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.medicoId == ''"
              small
              class="mr-2"
              color="#00730d"
              @click="desabilitar(item.id)"
              title="Desabilitar"
            >
              mdi-lock
            </v-icon>
            <v-icon
              v-else
              small
              color="#730000"
              class="mr-2"
              @click="habilitar(item.id)"
              title="habilitar"
            >
              mdi-lock-open-variant
            </v-icon>
          </template>
          <template v-slot:item.id_medico="{ item }">
           <h4 v-if="item.id_medico" style="color:green">Atendido</h4>
           <h4 v-else style="color: red">Em espera</h4>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import EspecialidadeService from "@/services/especialidade_service.js";
import FilaClienteService from "@/services/fila_cliente_service.js";
import AgendamentoSalaService from "@/services/agendamento_sala_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {},

  data: () => ({
    dialogCriarDisponibilidade: false,
    services: {
      especialidadeService: EspecialidadeService.build(),
    },
    listaEspecialidades: [],
     service: {
      filaClienteService: FilaClienteService.build(),
      agendamentoSalaService: AgendamentoSalaService.build(),
    },
    especialidadeId: null,
    disablePesquisa: true,
    menu: false,
    date: "",
    listaTeleconsultas: [
    ],
    search: "",
    loading: false,
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    headers: [
      { text: "Tutor", value: "nome_pessoa", sortable: false },
      { text: "Pet", value: "nome_pet", sortable: false },
      { text: "Dia", value: "data_criacao", sortable: false },
      { text: "Hora", value: "hora_criacao", sortable: false },
      { text: "Status", value: "id_medico", sortable: false },
      // { text: "", align: "end", value: "actions", sortable: false },
    ],
    valor: "",
  }),
  async mounted() {
    this.getEspecialidades();
    this.getFilaCliente();
  },
  watch: {
    date() {
      this.validarPesquisa();
    },
    especialidadeId() {
      this.validarPesquisa();
    },
  },
  computed: {
    allowCriarDisponibilidade() {
      return true;
    },
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    async getEspecialidades() {
      await this.services.especialidadeService
        // .getEspecialidadeList()
        .getEspecialidadeListByUnidadeId(
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaEspecialidades = data.map((pet) => {
            return {
              text: pet.descricao,
              value: pet.id_especialidade,
            };
          });
        });
    },
    async getFilaCliente() {
      // this.listaConsultas = [];
      await this.service.filaClienteService
        .getListByCliente('', {
          page: "1",
          per_page: "50",
          sort: "",
          desc: "",
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaTeleconsultas = data.filas.map((filaTelevet) => {
            let arrDia = filaTelevet.data_criacao.split('-')
            let dia = arrDia[2] + '/' +arrDia[1] + '/' + arrDia[0] 
            return {
              id_requisicao_procedimento: filaTelevet.id_requisicao_procedimento,
              id_cliente: filaTelevet.id_cliente,
              id_medico: filaTelevet.id_medico,
              id_pet: filaTelevet.id_pet,
              nome_pessoa: filaTelevet.nome_pessoa,
              nome_pet: filaTelevet.nome_pet,
              data_criacao: dia,
              hora_criacao: filaTelevet.hora_criacao,
            }
            
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getSalaConferencia(idRequisicaoProcedimento) {
      await this.service.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const url = data.urlmeeting;
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    pesquisaDisponibilidade() {
      this.valor = this.especialidadeId + "/" + this.date.replaceAll("-", "");
    },
    validarPesquisa() {
      if (this.date != "" && this.especialidadeId != null) {
        this.disablePesquisa = false;
      } else {
        this.disablePesquisa = true;
      }
    },
  },
};
</script>

<style>
.btn-app-primary {
  background-color: #1daf80 !important;
  border-color: #1daf80;
  color: white !important;
}
</style>
