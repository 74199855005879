<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">
            Consulta
            {{
              formatarData(
                horarioProp.data,
                horarioProp.hora_inicio,
                horarioProp.hora_final
              )
            }}</span
          >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-12 text-start padding-content">
            <h3>Tutor: {{ horarioProp.cliente_nome }}</h3>
            <h3>Pet: {{ horarioProp.pet_nome }}</h3>
            <h4>Procedimento: {{ horarioProp.procedimento_nome }}</h4>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10">
            <v-textarea
              :disabled="perfilId != 56 && perfilId != 1 && perfilId != 2"
              label="Observação"
              v-model="observacao"
              placeholder=" "
              rows="3"
            ></v-textarea>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-10">
            <v-text-field
              disabled
              label="Telefone"
              v-model="telefone"
              placeholder=""
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-10">
            <v-text-field
              disabled
              label="Responsável Agendamento"
              v-model="usuarioAgendamento"
              placeholder=""
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-5">
            <v-text-field
              disabled
              label="Data do Agendamento"
              v-model="dataAgendamento"
              placeholder=""
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-5">
            <v-text-field
              disabled
              label="Hora do Agendamento"
              v-model="horaAgendamento"
              placeholder=""
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-7 form-group clearfix mb-0">
            <v-select
              :disabled="perfilId != 56 && perfilId != 1 && perfilId != 2"
              style="padding-top: 0 !important"
              :items="statusItems"
              :item-text="text"
              :item-value="value"
              v-model="status"
              label="Status"
            />
          </div>
          <div class="col-3 form-group clearfix mb-0">
            <v-btn
              class="mb-2"
              outlined
              color="#3e682a"
              :disabled="status == ''"
              @click="alterarStatus"
              v-if="perfilId == 56 || perfilId == 1 || perfilId == 2"
            >
              Alterar
            </v-btn>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-3 form-group clearfix mb-0">
            <v-btn
              class="mb-2 mr-1"
              outlined
              color="#3e682a"
              :disabled="status == ''"
              :loading="loadingRemarcar"
              @click="funcaoRemarcar(horarioProp)"
              v-if="perfilId == 56 || perfilId == 1 || perfilId == 2"
            >
              <span v-if="!loadingRemarcar"> Remarcar </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
          </div>
          <div class="col-1 form-group clearfix mb-0"></div>
          <div class="col-3 form-group clearfix mb-0">
            <v-btn
              class="mb-2"
              outlined
              color="#3e682a"
              @click="confirmDesmarcarFunc()"
              v-if="perfilId == 56 || perfilId == 1 || perfilId == 2"
            >
              Desmarcar
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>

    <confirmation-dialog
      v-if="confirmDesmarcar"
      :enabled="confirmDesmarcar"
      :dialogColor="'error'"
      :title="'Desmarcar consulta'"
      :confirmationMessage="'Você tem certeza que deseja desmarcar essa consulta?'"
      :confirmationButtonMessage="'Confirmar'"
      :abortButtonMessage="'Cancelar'"
      :confirmationButtonColor="'error'"
      @abort="confirmDesmarcar = false"
      @confirm="desmarcarConsulta"
    ></confirmation-dialog>

    <v-dialog v-model="modalVerReagendamento" max-width="500px">
      <modal-reagendamento
        v-if="modalVerReagendamento"
        @closeRemarcar="closeRemarcar"
        @closeRemarcarSuccess="closeRemarcarSuccess"
        :detalhesAgenda="horarioProp"
      />
    </v-dialog>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ConfirmationDialog from "@/components/dialogs/confirmation";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import ModalReagendamento from "./ver_reagendamento_especialidade.vue";
export default {
  mixins: [VuexSnackbarMixin],
  components: { ConfirmationDialog, ModalReagendamento },
  props: {
    horarioProp: Object,
  },
  data: () => ({
    status: "",
    observacao: "",
    loadingDesmarcar: false,
    loadingRemarcar: false,
    confirmRemarcar: false,
    confirmDesmarcar: false,
    modalVerReagendamento: false,
    detalhesReagendamento: {},
    statusItems: [
      {
        value: 1,
        text: "A Confirmar",
      },
      {
        value: 2,
        text: "Confirmado",
      },
      {
        value: 3,
        text: "Atendido",
      },
      {
        value: 5,
        text: "Remarcado",
      },
      {
        value: 6,
        text: "Não Compareceu",
      },
    ],
    services: {
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),
  mounted() {},
  beforeMount() {
    console.log(this.horarioProp);
    this.status = parseInt(this.horarioProp.id_status);
    this.observacao = this.horarioProp.observacao;
    this.usuarioAgendamento = this.horarioProp.usuario_agendamento;
    this.dataAgendamento = this.horarioProp.data_criacao;
    this.horaAgendamento = this.horarioProp.hora_criacao;
    this.telefone =
      this.horarioProp.telefone != ""
        ? this.horarioProp.telefone
        : "Nenhum telefone!";
  },
  computed: {},
  watch: {},
  methods: {
    formatarData(data, horaInicio, horaFim) {
      let arrData = data.split("-");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${horaInicio.substring(
        0,
        horaInicio.length - 3
      )} - ${horaFim.substring(0, horaFim.length - 3)}`;
    },
    funcaoRemarcar() {
      this.modalVerReagendamento = true;
    },
    closeRemarcar() {
      this.modalVerReagendamento = false;
    },
    closeRemarcarSuccess() {
      this.modalVerReagendamento = false;
      this.$emit("close");
    },
    confirmDesmarcarFunc() {
      this.confirmDesmarcar = true;
    },
    desmarcarConsulta() {
      this.confirmDesmarcar = false;

      const request = {
        id_agenda: this.horarioProp.id_agenda_especialidade,
      };

      const agendamentoService = new AgendaEspecialidadeService();
      this.loadingRemarcar = true;
      agendamentoService.postDesmarcarAtendimento(
        {
          onSucess: (status) => (body) => {
            console.log(body.message);
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            if (status === 200) {
              this.$emit("close");
            }
          },
          onError: (error) => {
            this.$emit("remarcar", { error });
          },
          onEnd: () => {
            this.loadingRemarcar = false;
          },
        },
        request
      );
    },
    getModel() {
      return {
        id_agenda_especialidade: parseInt(
          this.horarioProp.id_agenda_especialidade
        ),
        id_status: this.status,
        observacao: this.observacao,
      };
    },
    async alterarStatus() {
      console.log(this.getModel());
      await this.services.agendaEspecialidadeService
        .postAgendamentoRecepcaoUpdate(this.getModel())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao alterar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta alterada com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        });
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.1rem !important;
}
.padding-content {
  padding: 15px 30px;
}
</style>
