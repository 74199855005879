<template>
  <v-card>
    <v-toolbar class="bg-verde" dark>
      <v-btn icon dark @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Adição de Valor</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="py-5 mx-2" v-if="!loading">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-select
            label="Convênio"
            outlined
            style="padding-top: 0 !important"
            :items="convenioList"
            :rules="[rules.required]"
            v-model="convenioId"
          />
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-select
            label="Unidade"
            outlined
            style="padding-top: 0 !important"
            :items="unidadeList"
            :rules="[rules.required]"
            v-model="unidadeId"
          />
        </div>

        <div class="col-lg-12 form-group clearfix ">
          <v-autocomplete
            label="Procedimento"
            outlined
            class="mb-0 mt-0"
            v-model="procedimentoId"
            :items="procedimentosList"
            :loading="loadingProcedimento"
            color="#3E682A"
            hide-no-data
            hide-details
            :search-input.sync="search"
            item-text="text"
            item-value="value"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <!-- <v-list-item-subtitle
                v-html="data.item.cpf"
              ></v-list-item-subtitle> -->
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!-- <v-select
            :items="procedimentosList"
            v-model="procedimentoId"
            :loading="loadingProcedimento"
            :rules="[rules.required]"
            style="padding-top: 0 !important"
          ></v-select> -->
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Data de Vigor"
            outlined
            style="padding-top: 0 !important"
            :rules="[rules.required]"
            clear-icon="clear"
            :type="'date'"
            v-model="dataVigor"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Valor Cliente"
            outlined
            type="number"
            style="padding-top: 0 !important"
            :rules="[rules.required]"
            v-model="novoValor"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Valor Interno"
            outlined
            type="number"
            style="padding-top: 0 !important"
            :rules="[rules.required]"
            v-model="novoValorInterno"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Valor de Repasse Medico"
            outlined
            type="number"
            style="padding-top: 0 !important"
            :rules="[rules.required]"
            v-model="novoValorRepasseMedico"
          ></v-text-field>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastroProcedimentoValor"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Salvar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
    <div v-else>
      <v-progress-circular
        :size="70"
        :width="5"
        class="my-5"
        indeterminate
        color="green"
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ProcedimentoValorService from "../../services/procedimento_valor_service.js";
import ConvenioService from "../../services/convenio_service.js";
import UnidadeService from "../../services/unidade_service";

export default {
  mixins: [Mixin],

  data: () => ({
    search: "",
    procedimentoId: 0,
    dataVigor: "",
    singleSelect: false,
    loading: false,
    formCadastro: "",
    novoValor: "",
    novoValorInterno: "",
    novoValorRepasseMedico: "",
    loadingProcedimento: false,
    procedimentosList: [],
    procedimentoValorService: ProcedimentoValorService.build(),
    convenioService: ConvenioService.build(),
    unidadeService: UnidadeService.build(),
    convenioList: [],
    convenioId: 0,
    unidadeList: [],
    unidadeId: 0,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  props: {},
  watch: {
    convenioId(value) {
      if (value > 0 && this.unidadeId) {
        this.getProcedimentos(value, this.unidadeId);
      }
    },
    async unidadeId(value) {
      if (value > 0 && this.convenioId) {
        await this.getProcedimentos(this.convenioId, value);
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.setUnidadaAndConvenio().finally(() => {
      this.loading = false;
    });
  },
  methods: {
    salvarEdicao() {},
    async setUnidadaAndConvenio() {
      this.convenioList = [];
      this.unidadeList = [];
      const [conveniosRequest, unidadesRequest] = await Promise.all([
        this.convenioService.getConvenios(),
        this.unidadeService.getUnidadeList(),
      ]);
      const { data } = await conveniosRequest.json();
      this.convenioList = data.convenios.map((convenio) => {
        return {
          value: convenio.convenio_id,
          text: convenio.convenio_descricao,
        };
      });
      this.unidadeList = unidadesRequest.map((unidade) => {
        return {
          value: unidade.unidadeId,
          text: unidade.nomeFantasia,
        };
      });
    },
    async getProcedimentos(convenioId, unidadeId) {
      this.loadingProcedimento = true;
      await this.procedimentoValorService
        .getProcedimentosByCadastroValor(convenioId, unidadeId)
        .then(async (response) => {
          const { data } = await response.json();
          console.log("data", response);
          this.procedimentosList = data.procedimentos.map((procedimento) => {
            return {
              value: procedimento.id_procedimento,
              text: procedimento.descricao_procedimento,
            };
          });
        })
        .finally(() => {
          this.loadingProcedimento = false;
        });
    },
    async cadastroProcedimentoValor() {
      this.loading = true;
      await this.procedimentoValorService
        .cadastroProcedimentoValor(this.getFormData())
        .then((response) => {
          if (response.status === 200) {
            this.$emit("response");
            this.convenioId = 0;
            this.procedimentoId = 0;
            this.unidadeId = 0;
            this.dataVigor = "";
            this.novoValor = "";
            this.novoValorInterno = "";
            this.novoValorRepasseMedico = "";
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormData() {
      const formData = new FormData();
      //formData.append("id_convenio_procedimento", this.convenioProcedimentoId);
      formData.append("id_unidade", this.unidadeId);
      formData.append("id_convenio", this.convenioId);
      formData.append("id_procedimento", this.procedimentoId);
      formData.append("data_inicio", this.dataVigor);
      formData.append("data_fim", "1900-01-01");
      formData.append("valor", this.novoValor);
      formData.append("valor_interno", this.novoValorInterno);
      formData.append("valor_repasse_medico", this.novoValorRepasseMedico);
      return formData;
    },
    close() {
      this.convenioId = 0;
      this.procedimentoId = 0;
      this.unidadeId = 0;
      this.dataVigor = "";
      this.novoValor = "";
      this.$emit("close");
    },
  },
  computed: {},
};
</script>
