<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80 !important; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Requisitar Consulta</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>

    <div class="p-5">
      <v-row class="mb-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersProcedimentos"
          :items="procedimentos"
          :search="search"
          class="elevation-1 col-6"
          :loading="loadingProcedimentos"
          :loading-text="'Carregando...'"
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="mb-2 btn-style-green" @click="listarConvenio(item)">
              Carregar Convênios
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>

        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersConvenios"
          :items="convenios"
          class="elevation-1 col-6"
          :loading="loadingConvenios"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="cadastrarRequisicao(item)"
            >
              Requisitar
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
      <v-row class="my-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersRequisicoes"
          :items="requisicoes"
          class="elevation-1 col-6"
          :loading="loadingRequisicoes"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="error"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="excluirRequisicao(item)"
              v-if="perfil == 2"
            >
              Excluir
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import ConvenioService from "@/services/convenio_service.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin],
  props: {
    petId: Object,
    tutorId: Object,
    petEncaminhar: Object,
    tutor: {},
  },
  data() {
    return {
      loadingProcedimentos: false,
      headersProcedimentos: [
        {
          text: "Procedimento",
          align: "start",
          sortable: true,
          value: "procedimentoDescricao",
        },
        {
          text: "Tipo Procedimento",
          align: "start",
          sortable: true,
          value: "tipoProcedimento",
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      procedimentos: [],
      headersConvenios: [
        {
          text: "Convênio",
          align: "start",
          sortable: true,
          value: "convenioDescricao",
        },
        {
          text: "Tipo Convênio",
          align: "start",
          sortable: true,
          value: "tipoConvenio",
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "valor",
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      loadingConvenios: false,
      convenios: [],
      headersRequisicoes: [
        {
          text: "Procedimento",
          align: "start",
          sortable: true,
          value: "procedimentoDescricao",
        },
        {
          text: "Convênio",
          align: "start",
          sortable: true,
          value: "convenioDescricao",
        },
        {
          text: "Tipo Convênio",
          align: "start",
          sortable: true,
          value: "tipoConvenio",
        },
        { text: "Ações", align: "center", value: "actions", sortable: false },
      ],
      requisicoes: [],
      search: "",
      loadingRequisicoes: false,
      convenioService: ConvenioService.build(),
      requisicaoExameService: RequisicaoExameService.build(),
      perfil: null,
      unidadeId: 0,
    };
  },
  watch: {
    search() {
      if (this.search.length >= 3) this.setProcedimentos();
    },
  },
  async beforeMount() {
    this.unidadeId = await JSON.parse(sessionStorage.getItem("vuex")).unidade
      .id;
    this.setRequisicoes();
    this.setProcedimentos();
    this.perfil = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    console.log(this.petEncaminhar);
  },
  methods: {
    async setProcedimentos() {
      this.loadingProcedimentos = true;
      await this.convenioService
        .getProcedimentosConsultas(this.unidadeId, this.search)
        .then(async (response) => {
          const { data } = await response.json();
          console.log(data);
          this.procedimentos = data.procedimentos.map((item) => {
            console.log(item);
            return {
              procedimentoId: item.id,
              procedimentoDescricao: item.descricao,
              tipoProcedimento: item.tipo_procedimento,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingProcedimentos = false;
        });
    },
    async listarConvenio(procedimento) {
      this.loadingConvenios = true;
      await this.convenioService
        .getConvenioPorUnidadeProcedimento(
          this.unidadeId,
          procedimento.procedimentoId
        )
        .then(async (response) => {
          const { data } = await response.json();
          this.convenios = data.map((item) => {
            return {
              convenioId: item.convenio_id,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              convenioProcedimentoId: item.convenio_procedimento_id,
              valor: Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.valor),
            };
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingConvenios = false;
        });
    },
    async cadastrarRequisicao(convenio) {
      await this.requisicaoExameService
        .requisitarConsulta(
          this.tutor && this.tutor.id_cliente
            ? this.tutor.id_cliente
            : this.tutorId.id_cliente,
          this.petEncaminhar && this.petEncaminhar.petId
            ? this.petEncaminhar.petId
            : this.petId.id_pet,
          ~~JSON.parse(sessionStorage.vuex).unidade.id,
          { id_convenio_procedimento: convenio.convenioProcedimentoId }
        )
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_SET_SNACKBAR({
              text: "Erro ao requisitar exame",
              color: "error",
            });
            await this.setRequisicoes();
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Requisição cadastrada com sucesso!",
            color: "sucess",
          });
          this.$_ACTIONS_setPetEncaminhar({
            petId:
              this.petEncaminhar && this.petEncaminhar.petId
                ? this.petEncaminhar.petId
                : this.petId.id_pet,
            tutorId:
              this.tutor && this.tutor.id_cliente
                ? this.tutor.id_cliente
                : this.petEncaminhar && this.petEncaminhar.tutorId
                ? this.petEncaminhar.tutorId
                : this.tutorId.id_cliente,
            nome_pet:
              this.petEncaminhar && this.petEncaminhar.nome_pet
                ? this.petEncaminhar.nome_pet
                : this.petId.nome,
            nome_tutor:
              this.tutor && this.tutor.nome
                ? this.tutor.nome
                : this.petEncaminhar && this.petEncaminhar.nome_tutor
                ? this.petEncaminhar.nome_tutor
                : this.tutorId.nome,
            tutor: this.tutor,
          });
          await this.setRequisicoes();
          return response.json();
        })
        .then((resp) => {
          localStorage.setItem("idRequisicaoProcedimento", resp.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setRequisicoes() {
      await this.requisicaoExameService
        .getRequisicoesConsultas(
          this.petEncaminhar && this.petEncaminhar.petId
            ? this.petEncaminhar.petId
            : this.petId.id_pet
        )
        .then(async (response) => {
          const { data } = await response.json();
          if (!data.length) return;
          this.requisicoes = data.map((item) => {
            return {
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
            };
          });
        });
    },
    excluirRequisicao(item) {
      this.loadingRequisicoes = true;
      const requisicaoId = parseInt(item.requisicaoId);
      this.requisicaoExameService
        .excluirRequisicaoConsulta(requisicaoId)
        .then((resp) => {
          if (resp.status != 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao excluir requisição",
              color: "error",
            });
            console.log(resp);
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Requisição excluída com sucesso!",
              color: "sucess",
            });
          }
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.setRequisicoes();
          this.loadingRequisicoes = false;
        });
    },
  },
};
</script>
<style>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 15 px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto;
}
</style>
