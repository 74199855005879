<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Tutores</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pacientes"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            v-model="dialogCadastro"
            max-width="500px"
            persistent
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mb-2 btn-style-green"
                v-bind="attrs"
                v-on="on"
                v-if="allowCadastrarBtn"
              >
                Novo Tutor
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #1daf80; color: #fff">
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Cadastrar Tutor</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <cadastro-novo-paciente
                v-if="dialogCadastro"
                @close="dialogCadastro = false"
                @response="atualizarListaPacientes"
              />
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-3">
            <v-text-field
              v-model="searchId"
              label="Id"
              class="mr-5 col-4"
              append-icon="mdi-magnify"
              @click:append="searchTutorById"
              @keydown.enter="searchTutorById"
              single-line
              hide-details
            ></v-text-field>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Nome ou CPF"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            v-if="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-paciente
              :paciente="paciente_detalhe"
              :visualizar="visualizar"
              :key="render"
              @close="save($event)"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogObs"
            fullscreen
            hide-overlay
            scrollable
            transition="dialog-bottom-transition"
          >
            <obs-assistente
              :paciente="paciente"
              :key="render"
              @close="save($event)"
            />
          </v-dialog>
          <v-dialog
            v-model="dialogNovaSenha"
            width="500"
            transition="dialog-bottom-transition"
          >
            <nova-senha
              v-if="dialogNovaSenha"
              :infosUsuario="infoUsuario"
              @close="closeNovaSenha($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="perfilId == 1"
          title="Alterar Senha"
          small
          class="mr-2"
          @click="  novaSenha(item.id_usuario, item.nome)"
        >
        mdi-form-textbox-password
        </v-icon>
        <v-icon
          v-if="allowVisualizarComandas"
          title="Visualizar comandas"
          small
          class="mr-2"
          color="primary"
          @click="showComandas(item)"
        >
          mdi mdi-magnify
        </v-icon>
        <v-icon
          v-show="$_GETTER_perfil.description === 'assistente'"
          small
          class="mr-2"
          @click="editItemObs(item)"
        >
          fas fa-file-invoice-dollar
        </v-icon>
        <v-icon title="Pets" small class="mr-2" @click="openDialogPets(item)">
          mdi-dog-side
        </v-icon>
        <v-icon
          title="Visualizar cadastro"
          v-if="allowVisualizarBtn"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
        <v-icon
          title="Editar cadastro"
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          fas fa-edit
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarListaPacientes">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogPets"
      v-if="dialogPets"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark style="background-color: #1daf80; color: #fff">
          <v-btn icon dark @click="dialogPets = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cadastrar Pet</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <ConsultaPet :petId="petId" :tutor="tutor" />
      </v-card>
    </v-dialog>

    <template v-if="dialogComandas">
      <v-dialog
        v-model="dialogComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comandas </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>

          <modal-comandas :tutor="tutorComanda" />
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import CadastroEdicaoPaciente from "@/components/pacientes/cadastro_edicao_paciente.vue";
import ObsAssistente from "@/components/pacientes/obs_assistente.vue";
import CadastroNovoPaciente from "@/components/pacientes/cadastro_novo_paciente.vue";
import NovaSenha from "@/components/alterar_senha/alterar_senha.vue";
import PacienteService from "@/services/paciente_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import endpoints from "../router/endpoints";
import AuthService from "../services/auth_service";
import ConsultaPet from "@/components/pacientes/consulta_pets_paciente.vue";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";

export default {
  mixins: [Mixin],
  components: {
    CadastroEdicaoPaciente,
    CadastroNovoPaciente,
    ObsAssistente,
    ConsultaPet,
    ModalComandas,
    NovaSenha
  },

  data: () => ({
    dialogComandas: false,
    tutorComanda: {},
    paciente_detalhe: {},
    render: 0,
    dialog: false,
    petId: "",
    dialogPets: false,
    dialogObs: false,
    loading: false,
    search: "",
    searchId: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    dialogCadastro: false,
    paciente: {},
    visualizar: false,
    pacientes: [],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "id_cliente",
      },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    services: {
      authService: AuthService.build(),
    },
    tutor: {},
    perfilId: JSON.parse(sessionStorage.getItem("vuex")).perfil.id,
    infoUsuario: {},
    dialogNovaSenha: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Novo Procedimento"
        : "Editar Procedimento";
    },
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["PacienteController::POST->register"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["PacienteController::POST->updatePacienteData/$1"]
      );
    },
    allowVisualizarBtn() {
      return this.services.authService.temPermissao(
        endpoints["PacienteController::GET->getPacienteByUsuarioID/$1"]
      );
    },
    allowVisualizarComandas() {
      return (
        this.services.authService.temPermissao(
          endpoints[
            "ComandaController::GET->findComandasByClienteIdByUnidadeId/$1/$2"
          ]
        ) ||
        this.services.authService.temPermissao(
          endpoints["ComandaController::GET->findComandasByClienteId/$1"]
        )
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaPacientes();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaPacientes();
      }
    },
    searchId(value, oldValue) {
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarListaPacientes(true);
      }
    },
  },

  mounted() {
    if (
      !this.services.authService.temPermissao(
        endpoints["PacienteController::GET->getPacienteList"]
      )
    ) {
      this.services.authService.redirectToUnauthorizedRoute();
    }
    this.atualizarListaPacientes();
    const infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"))
      .NomeTutor;
    this.search = this.$_GETTERS_petEncaminhar.nome_tutor || infoPaciente;
  },

  methods: {
    searchTutorById() {
      if (this.searchId > 1) {
        this.options.page = 1;
        this.atualizarListaPacientes(true);
      }
    },
    showComandas(item) {
      console.log(item);
      this.dialogComandas = true;
      this.tutorComanda = item;
    },
    forceRender() {
      this.render += 1;
    },
    atualizarListaPacientes(filterId) {
      const pacienteService = new PacienteService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = body.data.pagination.num_rows;
          this.pacientes = body.data.pacientes.map((paciente) => {
            return paciente;
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      pacienteService.getAll(
        onSucess,
        onError,
        onEnd,
        this.options.page,
        this.options.itemsPerPage,
        filterId ? this.searchId : this.search,
        this.options.ordenacao,
        this.options.desc,
        filterId
      );
    },

    loadingPaciente(id) {
      this.paciente_detalhe = {};
      const pacienteService = new PacienteService();
      pacienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.paciente_detalhe = body.data;
              this.dialog = true;
            }
          },
          onError: (error) => {
            this.messageSnackbar = error;
            this.typeMessageSnackbar = "error";
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },

    editItem(item, bool) {
      this.editedIndex = this.pacientes.indexOf(item);
      this.paciente = item;
      this.visualizar = bool;
      this.editedItem = Object.assign({}, item);
      this.loadingPaciente(this.paciente.id);
    },
    editItemObs(item) {
      this.forceRender();
      this.editedIndex = this.pacientes.indexOf(item);
      this.paciente = item;
      this.editedItem = Object.assign({}, item);
      this.dialogObs = true;
    },

    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.dialogObs = false;
      this.atualizarListaPacientes();
      this.forceRender();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.pacientes[this.editedIndex], this.editedItem);
      } else {
        this.pacientes.push(this.editedItem);
      }
      this.paciente_detalhe = false;
      this.options.ordenacao = false;
      this.options.desc = false;
      this.close();
      this.atualizarListaPacientes();
      this.forceRender();
    },
    openDialogPets(item) {
      // console.log(item);
      this.tutor = item;
      this.petId = item.id_cliente.toString();
      this.dialogPets = true;
    },
    novaSenha(idUsuario, nomeUsuario){
    this.infoUsuario = {
      idUsuario: idUsuario,
      nomeUsuario: nomeUsuario,
    }
    this.dialogNovaSenha = true
  },
  closeNovaSenha(){
    this.atualizarListaPacientes();
    this.dialogNovaSenha = false
  }
  },
};
</script>
<style scoped>
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
