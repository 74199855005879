<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('closeRemarcar')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline"> Reagendamento de Consulta </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-6 text-start padding-content">
            <label>Data de agendamento</label>
            <v-text-field
              v-model="dataAgendamento"
              clear-icon="clear"
              :type="'date'"
              item-text="dataitem"
              item-value="id"
              :outlined="false"
              @keyup="search_horario()"
              @blur="search_horario()"
              solo
            />
          </div>

          <div class="col-6 text-start padding-content">
            <label>Horário Disponível</label>
            <v-select
              v-model="horario"
              item-text="horariotext"
              item-value="horariovalue"
              :items="horarios"
              placeholder=" "
              label="Horário disponível"
              color="black"
              solo
            >
            </v-select>
          </div>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('closeRemarcar')"> Fechar </v-btn>

          <!-- BOTÂO PARA REAGENDAMENTO -->
          <v-btn
            color="error"
            text
            :loading="loadingRemarcar"
            @click="remarcarConsulta()"
            style="background-color: #ffa500; color: #fff !important"
          >
            <span v-if="!loadingRemarcar"> Remarcar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
export default {
  mixins: [VuexSnackbarMixin],
  components: {},
  props: {
    detalhesAgenda: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    status: "",
    observacao: "",
    detalhesReagendamento: {},
    loadingRemarcar: false,
    dataAgendamento: "",
    params: [],
    horarios: [],
    horario: "",
    statusItems: [
      {
        value: 1,
        text: "A Confirmar",
      },
      {
        value: 2,
        text: "Confirmado",
      },
      {
        value: 3,
        text: "Atendido",
      },
      {
        value: 4,
        text: "Cancelado",
      },
      {
        value: 5,
        text: "Remarcado",
      },
      {
        value: 6,
        text: "Não Compareceu",
      },
    ],
    services: {
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),
  mounted() {},
  beforeMount() {},
  computed: {},
  watch: {},
  methods: {
    remarcarConsulta() {
      this.confirmDesmarcar = false;

      const request = {
        id_agenda_antiga: this.detalhesAgenda.id_agenda_especialidade,
        id_agenda_nova: this.horario,
      };

      const agendamentoService = new AgendaEspecialidadeService();
      this.loadingRemarcar = true;
      agendamentoService.postReagendarAtendimento(
        {
          onSucess: (status) => (body) => {
            console.log(body.message);
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            if (status === 200) {
              this.$emit("closeRemarcarSuccess");
              this.$emit("closeRemarcaAtualiza");
            }
          },
          onError: (error) => {
            this.$emit("remarcar", { error });
          },
          onEnd: () => {
            this.loadingRemarcar = false;
          },
        },
        request
      );
    },
    gerar_horario_atual() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    search_horario() {
      console.log("Loucura");
      if (
        !this.dataAgendamento ||
        this.dataAgendamento.length < 7 ||
        this.loading
      ) {
        this.horarios = [];
        return;
      }

      this.params["id_procedimento"] = this.detalhesAgenda.procedimento_id;
      this.params["data_agenda"] = this.dataAgendamento;
      this.params["id_unidade"] = this.detalhesAgenda.id_unidade;

      const agendaService = new AgendaEspecialidadeService();
      agendaService.getHorariosDisponiveisFromDate(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.agendas.forEach((form) => {
                let horario = [];
                horario["horariovalue"] = form.id;
                horario["horariotext"] =
                  form.hora_inicio + " - " + form.hora_final;
                this.horarios.push(horario);
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.pacientes = [];
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.params
      );
    },
  },
};
</script>

<style scoped>
.headline {
  font-size: 1.1rem !important;
}
.padding-content {
  padding: 15px 30px;
}
</style>
