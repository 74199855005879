<template>
  <div>
    <div class="p-lg-3">
      <div class="row  align-center" style="gap: 2rem">
        <!-- <h3 class="font-weight-bold text-left">Comanda Itens</h3> -->
        <h4 v-if="comanda.comandaEstaAberta">
          Valor total Selecionado:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(sum)
          }}</b>
        </h4>
        <h4>
          Valor Total:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(comanda.valor)
          }}</b>
        </h4>
        <h4>
          Valor Pago:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valorPagoComanda)
          }}</b>
        </h4>
        <h4>
          Valor Estornado:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valorTotalEstornado)
          }}</b>
        </h4>
        <h4>
          Crédito Cliente:
          <b class="font-weight-regular">{{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valorCreditoCliente)
          }}</b>
        </h4>
        <div style="display: flex; gap: 2rem" class="align-center mr-5">
          <v-btn
            class="success"
            @click="() => (dialogPagarComandas = true)"
            v-if="
              (valorPagoComanda != comanda.valor) &
                comanda.comandaEstaAberta &
                (comanda.valor > 0) &
                (perfil.id == 56 || perfil.id == 1)
            "
            small
            :disabled="itemsEstorno.length > 0"
          >
            Pagar
          </v-btn>
          <v-icon
            @click="showDialogEstorno"
            title="LANÇAR ESTORNO"
            x-large
            color="grey darken-3"
            v-if="
              (comanda.valor > 1) &
                (itemsEstorno.length > 0) &
                (perfil.id == 56 || perfil.id == 1)
            "
          >
            mdi-cash-sync
          </v-icon>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogCodigoAuth" v-if="dialogCodigoAuth" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Pagamento </v-card-title>

        <v-text-field
          class="mx-5 pt-5"
          label="Bandeira do cartão"
          required
          v-model="bandeira"
          v-if="isCard"
        ></v-text-field>

        <v-text-field
          class="mx-5"
          label="Código de autorização"
          required
          v-model="codAuth"
          v-if="isCard"
        ></v-text-field>

        <v-text-field
          class="mx-5"
          label="Número de Parcelas"
          v-if="isCard & isCreditCard"
          required
          v-model="parcelas"
        ></v-text-field>

        <textarea
          rows="4"
          cols="58"
          class="mt-5 p-2 border"
          placeholder="Observações"
          maxlength="400"
          v-model="observacao"
        ></textarea>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2"
            type="number"
            text
            color="error"
            @click="dialogCodigoAuth = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="success"
            class="mb-2"
            type="number"
            @click="marcarPago(codAuth, bandeira)"
            v-if="isCard"
            :disabled="!codAuth"
          >
            Marcar Pago
          </v-btn>
          <v-btn
            color="success"
            class="mb-2"
            type="number"
            @click="marcarPago(0, 0)"
            v-if="!isCard"
          >
            Marcar Pago
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      item-key="comanda_item_id"
      show-select
      class="elevation-1"
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:item.valor="{ item }">
        {{  parseFloat(item.valor).toLocaleString("pt-br", {
             style: "currency",
            currency: "BRL",
           }), }}
      </template>
      <template v-slot:header.data-table-select="{}">
        <v-simple-checkbox
          @input="selectAllToggle"
          :value="selectedAll"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="
            comanda.comandaEstaAberta &
              (item.status == 1) &
              (item.tipo_convenio_id != 1) &
              (perfil.id == 56 || perfil.id == 1)
          "
          :value="isSelected"
          :readonly="item.status_descricao === 'PAGO'"
          :disabled="item.status_descricao === 'PAGO'"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="(item.status == 4) & (perfil.id == 56 || perfil.id == 1)"
          text
          rounded
          title="Gerar recibo de estorno"
          @click="reciboEstornoPDF(item.extorno_id)"
        >
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
        <v-icon
          color="error"
          @click="excluirItemComanda(item)"
          title="EXCLUIR"
          v-if="
            comanda.comandaEstaAberta &
              (item.status == 1) &
              (perfil.id == 56 || perfil.id == 1)
          "
        >
          mdi mdi-close-thick
        </v-icon>
        <v-btn
          title="ESTORNAR"
          v-if="(item.status == 2) & (perfil.id == 56 || perfil.id == 1)"
          small
          color="success"
          class="border"
          @click="selectItemEstorno(item)"
          :ref="item.comanda_item_id"
        >
          <span class="text-grey">
            Estornar
          </span>
        </v-btn>
      </template>
    </v-data-table>
    <!--template v-slot:no-data>
      <p class="text-center">Nenhum registro encontrado</p>
      <v-btn color="#3E682A" dark> Atualizar </v-btn>
    </template-->
    <!--div class="text-center pt-2">
       <span class="mr-4">Valor a Pagar: R$ {{sum.toFixed(2).replace('.', ',')}}</span>
        <v-btn
          color="#3E682A"
          dark
          @click="()=>dialogPagarComandas = true"
        >
          Pagar
        </v-btn>
      </div-->
    <template v-if="dialogPagarComandas">
      <v-dialog
        v-model="dialogPagarComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark style="background-color: #3e682a; color: #fff">
            <v-btn icon dark @click="dialogPagarComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comanda Detalhes </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <ModalPagarComandas
            :comandas="selected"
            @close="() => (dialogClose = true)"
          />
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog v-model="dialogEstorno" v-if="dialogEstorno" max-width="500px">
        <v-card>
          <v-toolbar dark class="mb-2 bg-verde">
            <v-btn icon dark @click="dialogEstorno = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Lançar Estorno</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-5 mx-auto">
            <h3>Valor do estorno: R$ {{ estornarValorTotal }}</h3>
            <v-select
              :items="tiposEstorno"
              item-text="descricao"
              label="Tipos de estorno"
              outlined
              class="mt-5"
              v-model="tipoEstornoSelecionado"
              return-object
            ></v-select>
            <!-- <v-text-field
              v-model="forma_devolucao"
              :rules="[valueRule]"
              outlined
              label="Forma de devolução"
              v-if="tipoEstornoSelecionado.id == 1"
            ></v-text-field> -->
            <v-textarea
              outlined
              label="Observações"
              v-model="observacoesEstorno"
              rows="3"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-end pb-5 mr-2">
            <v-btn text @click="dialogEstorno = false">Cancelar</v-btn>
            <v-btn
              @click="estornar"
              color="success"
              :disabled="
                !tipoEstornoSelecionado.id ||
                  estornarValorTotal < 1 ||
                  loadingEstorno
              "
              style="width: 120px"
            >
              <span v-if="!loadingEstorno">Estornar</span>
              <v-progress-circular
                v-else
                :width="3"
                :size="20"
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Recibo-estorno"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="html2Pdf"
        >
          <section slot="pdf-content" class="m-4 pdf-content">
            <div class="text-center">
              <img
                src="../../assets/images/logo-cvpc.png"
                alt="Logo Centro Veterinário Popular do Ceará"
                width="50px"
              />
            </div>
            <h3 class="text-center mb-5" style="margin-top: 1rem">
              RECIBO DE ESTORNO
            </h3>
            <p class="text-justify">
              Eu, <b>{{ nome_cliente }}</b
              >, CPF <b>{{ tutor.cpf }}</b
              >, recebi de {{ unidade.razao_social }}, endereço:
              {{
                unidade.logradouro +
                  " - " +
                  unidade.bairro +
                  " | " +
                  unidade.cidade +
                  "/" +
                  unidade.estado
              }}, CNPJ: {{ unidade.cnpj }}, o valor de
              {{
                new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(valorEstornoRecibo)
              }}, referente a não prestação dos serviços:
              {{ itemsReciboEstorno.map((item) => item.desc).join(", ") }}.
            </p>
            <p>Forma de estorno: {{ tipoEstornoRecibo.descricao }}</p>
            <p>
              Este documento dá quitação plena e irrevogável da referência
              acima.
            </p>
            <!-- <div
              class="w-80 d-flex align-items-center"
              style="justify-content: space-between"
            >
              <div>
                <h4>{{ unidade.nome }}</h4>
                <span>{{ unidade.logradouro + " - " + unidade.bairro }}</span>
                <span>{{ ". " }}</span>
                <span>{{ unidade.cidade + "/" + unidade.estado }}</span>
                <p>{{ "CNPJ: " + unidade.cnpj }}</p>
              </div>

              <div class="mr-4">
                <h3>
                  {{
                    new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(valorEstornoRecibo)
                  }}
                </h3>
              </div>
            </div> -->

            <!-- <div class="border mx-1 mr-4 p-2">
              <p>
                Recebido de: <b>{{ usuario.nome }}</b>
              </p>

              <p>
                A quantia de:
                <b>{{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(valorEstornoRecibo)
                }}</b>
              </p>

              <p>
                Referente a:
                <b
                  >{{ nome_cliente }} (tutor do pet: {{ pet_nome }}) -
                  ESTORNO</b
                >
              </p>
              <p>
                Tipo de estorno:
                <b>{{ tipoEstornoSelecionado.descricao }} </b>
              </p>
            </div>-->

            <p class="d-flex justify-content-center mt-5">
              {{ unidade.cidade }}/{{ unidade.estado }},
              <b>{{ dataEstorno }}</b>
            </p>

            <p class="text-center">
              _________________________________________
            </p>
            <p class="text-center mt-n2">{{ nome_cliente }}</p>
          </section>
        </vue-html2pdf>
      </div>
    </template>
  </div>
</template>
<style scoped></style>
<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import FormaPagamentoService from "../../services/forma_pagamento_service";
import TipoEstornoService from "@/services/tipo_estorno_service.js";
import EstornoService from "@/services/estorno_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ModalPagarComandas from "@/components/pacientes/modal_pagar_comandas.vue";
import VueHtml2pdf from "vue-html2pdf";
import CreditoClienteService from "../../services/credito_cliente_service";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  components: {
    ModalPagarComandas,
    VueHtml2pdf,
  },
  data: () => ({
    codAuth: null,
    dialogPagarComandas: false,
    disabledCount: 0,
    dialogClose: false,
    parcelas: 0,
    singleSelect: false,
    selected: [],
    sum: 0,
    observacao: "",
    dialogDetalhesComandas: false,
    isCard: false,
    isCreditCard: false,
    dialogCodigoAuth: false,
    comandasItens: [],
    loading: false,
    formasdePagamento: [],
    formaPagamentoSelecionada: {},
    headers: [
      {
        text: "Procedimento",
        align: "start",
        sortable: false,
        value: "procedimentoDescricao",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: false,
        value: "tipoProcedimentoDescricao",
      },
      {
        text: "Valor",
        align: "start",
        sortable: false,
        value: "valor",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: false,
        value: "convenioDescricao",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "statusDescricao",
      },
      { text: "Data criado", value: "dataCriado", sortable: false },
      { text: "", align: "center", value: "actions", sortable: false },
      // { text: "Pagar", align: "center", value: "select", sortable: false },
    ],
    desserts: [],
    comandaService: ComandaService.build(),
    formaPagamentoService: FormaPagamentoService.build(),
    tipoEstornoService: TipoEstornoService.build(),
    estornoService: EstornoService.build(),
    dialogEstorno: false,
    tiposEstorno: [],
    tipoEstornoSelecionado: {},
    valorEstorno: null,
    valorTotalEstornado: null,
    observacoesEstorno: "",
    valorExcedido: false,
    bandeira: "",
    valorPagoComanda: null,
    loadingEstorno: false,
    unidade: {},
    usuario: {},
    valorEstornoRecibo: "",
    dataEstorno: "",
    itemsEstorno: [],
    valorCreditoCliente: "",
    itemsReciboEstorno: [],
    forma_devolucao: "",
    perfil: null,
    nome_cliente: "",
    tipoEstornoRecibo: "",
  }),
  props: {
    comanda: {
      type: Object,
      required: true,
    },
    tutor: {
      required: true,
    },
  },
  watch: {
    comanda() {
      this.comandasItens = [];
      this.setComandaItens();
    },

    dialogClose() {
      if (this.dialogClose === true) {
        this.dialogPagarComandas = false;
        this.$emit("close");
      }
    },

    selected() {
      //let sum = 0
      this.sum = 0;
      console.log(this.selected);
      for (let i = 0; i < this.selected.length; i++) {
        this.sum = this.sum + parseFloat(this.selected[i].valor);
      }
    },
    close() {
      this.dialogPagarComandas = false;
      this.$emit("close");
    },
    dialogCodigoAuth() {
      this.observacao = "";
      this.bandeira = "";
      this.bandeira = "";
      this.codAuth = null;
    },
    tipoEstornoSelecionado() {
      console.log(this.tipoEstornoSelecionado);
    },
  },
  beforMount() {
    document.getElementsByTagName("i")[0].style("display:none;");
  },
  async mounted() {
    this.perfil = JSON.parse(sessionStorage.vuex).perfil;
    await this.setComandaItens();
    this.getFormasPagamento();
    const self = this;
    this.desserts.map((item) => {
      if (item.status != 1 || item.tipo_convenio_id == 1) {
        self.disabledCount += 1;
      }
    });
    this.comandasItens.map((item) => {
      if (item.status == 2) {
        this.formaPagamentoSelecionada = Object.assign(
          this.formaPagamentoSelecionada,
          { [item.comanda_item_id]: item.id_forma_pagamento }
        );
      }
    });
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade;
    await this.getTiposEstorno();
    this.valoresEstornados();
  },
  // created() {
  //   const self = this;
  //   this.desserts.map((item) => {
  //     if ((item.status == 1) & (item.tipo_convenio_id != 1))
  //       self.disabledCount += 1;
  //   });
  // },
  methods: {
    async getCreditoCliente() {
      const creditoClienteService = new CreditoClienteService();
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
      const clienteId = this.comanda.cliente_id;

      const credito = await creditoClienteService.getCreditosCliente(
        unidadeId,
        clienteId
      );

      const creditos = await credito.data.creditos;
      this.valorCreditoCliente = creditos.reduce(
        (acc, cur) => acc + parseFloat(cur.valor),
        0
      );
    },
    selectItemEstorno(item) {
      const itemEmEstorno = this.itemsEstorno.filter(
        (itemEstorno) => itemEstorno.comanda_item_id == item.comanda_item_id
      );
      if (itemEmEstorno.length == 0) {
        this.itemsEstorno.push({
          comanda_item_id: item.comanda_item_id,
          valor: parseFloat(item.valor),
        });

        this.itemsReciboEstorno.push({
          comanda_item_id: item.comanda_item_id,
          desc: item.procedimentoDescricao,
        });
        console.log(this.itemsReciboEstorno);

        this.$refs[item.comanda_item_id].text = true;
        this.$refs[item.comanda_item_id].color = "grey";
      } else {
        this.itemsEstorno = this.itemsEstorno.filter(
          (itemEstorno) => itemEstorno.comanda_item_id != item.comanda_item_id
        );

        this.itemsReciboEstorno = this.itemsReciboEstorno.filter(
          (itemEstorno) => itemEstorno.comanda_item_id != item.comanda_item_id
        );
        console.log(this.itemsReciboEstorno);

        this.$refs[item.comanda_item_id].text = false;
        this.$refs[item.comanda_item_id].color = "success";
      }
    },
    async reciboEstornoPDF(estornoId) {
      await this.estornoService
        .getReciboEstorno(estornoId)
        .then((resp) => resp.data.dados_recibo[0])
        .then((resp) => {
          this.usuario = {
            nome: resp.colaborador,
          };
          this.valorEstornoRecibo = resp.valor;
          this.nome_cliente = resp.nome_cliente;
          this.pet_nome = resp.pet_nome;
          this.tipoEstornoRecibo = this.tiposEstorno.find(
            (tipo) => tipo.id == resp.id_tipo_extorno
          );

          const meses = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ];
          let data = new Date();
          let dataFormatada =
            data.getDate() +
            " " +
            meses[data.getMonth()] +
            " " +
            data.getFullYear();
          this.dataEstorno = dataFormatada;
        })
        .then(() => {
          this.$refs.html2Pdf.generatePdf();
        })
        .catch((error) => console.log(error));
    },
    selecionarFormaPagamento(event, id) {
      this.formaPagamentoSelecionada = Object.assign(
        this.formaPagamentoSelecionada,
        { [id]: event }
      );
    },
    selectAllToggle() {
      if (this.selected.length == this.desserts.length - this.disabledCount) {
        this.selected = [];
      } else {
        this.selected = [];
        this.desserts.forEach((item) => {
          if ((item.status == 1) & (item.tipo_convenio_id != 1)) {
            this.selected.push(item);
          }
        });
      }
    },
    async getFormasPagamento() {
      this.formasdePagamento = await this.formaPagamentoService
        .getFormaPagamentoPaginatedList()
        .then((resp) => resp.json())
        .then((resp) => {
          return resp.data.forma_pagamento.map((item) => {
            return { text: item.descricao, id: item.id, codauth: item.codauth };
          });
        });
    },
    async setComandaItens() {
      this.loading = true;
      const response = await this.comandaService.getComandaItensByComandaId(
        this.comanda.comanda_id
      );
      this.desserts = await response.map((item) => {
        return {
          ...item,
          procedimentoDescricao: item.procedimento_descricao,
          tipoProcedimentoDescricao: item.tipo_procedimento_descricao,
          valor: item.valor,
          convenioDescricao: item.tipo_convenio_descricao,
          statusDescricao: item.status_descricao,
          isPago: item.status == 2,
          dataCriado: new Date(
            item.data_criada + " " + item.hora_criada
          ).toLocaleString("pt-BR"),
        };
      });
      this.valorPagoComanda = response
        .filter((item) => item.status == "2")
        .reduce((acc, cur) => {
          return acc + parseFloat(cur.valor);
        }, 0);
      this.getCreditoCliente();
      this.loading = false;
    },
    codigoAuth(item) {
      let payMethod = 0;

      for (let prop in this.formaPagamentoSelecionada) {
        if (prop === item.comanda_item_id) {
          payMethod = this.formaPagamentoSelecionada[prop];
        }
      }
      if (payMethod === "1") {
        this.parcelas = 1;
        this.isCreditCard = true;
      } else {
        this.parcelas = 0;
        this.isCreditCard = false;
      }
      localStorage.setItem("itemComanda", JSON.stringify(item));

      const auth = this.formasdePagamento.filter((formPag) => {
        return (
          formPag.id == this.formaPagamentoSelecionada[item.comanda_item_id]
        );
      })[0].codauth;

      if (auth == "S") {
        this.isCard = true;
        this.dialogCodigoAuth = true;
      } else {
        this.isCard = false;
        this.codAuth = 0;
        this.bandeira = 0;
        this.dialogCodigoAuth = true;
      }
    },
    async marcarPago(codauth, bandeira) {
      this.loading = true;
      const item = await JSON.parse(localStorage.getItem("itemComanda"));

      this.comandaService
        .pagarItemComanda({
          comandaId: item.comanda_id,
          comandaItemId: item.comanda_item_id,
          idFormaPagamento: this.formaPagamentoSelecionada[
            item.comanda_item_id
          ],
          codauth: parseInt(codauth),
          bandeira: bandeira,
          parcelas: this.parcelas,
          observacao: this.observacao,
        })
        .finally(() => {
          this.loading = false;
          this.dialogCodigoAuth = false;
          this.setComandaItens();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    excluirItemComanda(item) {
      this.loading = true;
      this.comandaService
        .excluirItemComanda({
          comandaId: item.comanda_id,
          comandaItemId: item.comanda_item_id,
        })
        .finally(() => {
          this.loading = false;
          this.setComandaItens();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    showDialogEstorno() {
      this.valorEstorno = null;
      this.tipoEstornoSelecionado = {};
      this.dialogEstorno = true;
    },
    estornar() {
      console.log(this.itemEstorno);
      if (
        parseFloat(this.valorEstorno) + this.valorTotalEstornado >
        parseFloat(this.valorPagoComanda)
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Valor estornado deve ser menor que o valor pago",
          color: "error",
        });
        return;
      }

      // if ((this.tipoEstornoSelecionado.id == 1) & !this.forma_devolucao) {
      //   this.$_ACTIONS_showSnackbarMessage({
      //     message: 'O campo "forma de devolução" é obrigatório',
      //     color: "error",
      //   });
      //   return;
      // }

      const estorno = {
        comanda_id: parseInt(this.comanda.comanda_id),
        tipo_id: this.tipoEstornoSelecionado.id,
        valor: this.estornarValorTotal,
        observacao: this.observacoesEstorno,
        itens: this.itemsEstorno,
      };

      const responseFunction = {
        onSucess: (status) => async (body) => {
          if (status == 200) {
            const resp = body.data;
            const estornoId = resp.id_extorno;
            await this.reciboEstornoPDF(estornoId);
            this.$_ACTIONS_showSnackbarMessage({
              message: "Estorno lançado com sucesso",
              color: "success",
            });
          } else {
            let itensExecutados = [];
            body.itens.forEach((item) => {
              itensExecutados.push(
                this.desserts.filter((i) => i.comanda_item_id == item)[0]
                  .procedimento_descricao
              );
            });

            this.$_ACTIONS_showSnackbarMessage({
              message: body.message + ": " + itensExecutados.join(", "),
              color: "error",
            });
          }
        },
        onError: () => {},
        onEnd: () => {
          this.loadingEstorno = false;
          this.dialogEstorno = false;
          this.itemsEstorno = [];
          this.setComandaItens();
          this.valoresEstornados();
        },
      };

      console.log(estorno);

      this.loadingEstorno = true;

      this.estornoService.setEstorno(responseFunction, estorno);
    },
    valueRule(value) {
      if (!value) {
        return "Campo Obrigatório";
      }
    },
    async getTiposEstorno() {
      await this.tipoEstornoService
        .getTipoEstornoPaginatedList({
          page: null,
          per_page: null,
          sort: null,
          desc: null,
          search: null,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { tipo_procedimentos } = data;
          this.tiposEstorno = tipo_procedimentos.map(({ id, descricao }) => {
            return {
              id,
              descricao,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    valoresEstornados() {
      this.estornoService
        .getEstornoByComandaId(this.comanda.comanda_id)
        .then((resp) => {
          this.valorTotalEstornado = resp.data.estornos.reduce(
            (acc, cur) => acc + parseFloat(cur.valor),
            0
          );
        });
    },
  },
  computed: {
    estornarValorTotal() {
      return this.itemsEstorno.reduce((acc, cur) => acc + cur.valor, 0);
    },
    selectedAll() {
      return this.selected.length == this.desserts.length - this.disabledCount
        ? true
        : false;
    },
  },
};
</script>
<style>
.pdf-content {
  font-family: "Times New Roman", Times, serif;
  color: #000;
}
</style>
