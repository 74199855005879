<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Declaração</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div>
            <!-- <v-select
              v-model="tipo"
              :items="tipos_declaracao"
              label="Tipo de declaração"
              class="mb-2"
            >
            </v-select> -->
            <v-select
              v-model="convenioSelecionado"
              :items="convenios"
              label="Convênio"
              class="mb-2"
            >
            </v-select>
            <div class="d-flex align-center p-0">
              <v-checkbox
                class="m-0 mt-n2  p-0"
                v-model="preencherDadosTutor"
                color="#3E682A"
                hide-details
              ></v-checkbox>
              <label class="mt-1">Preecher dados do tutor</label>
            </div>
          </div>
        </v-container>
        <div class="row my-2">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green" @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    tipo: "Declaração de horas",
    tipos_declaracao: ["Atestado", "Declaração de horas"],
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
    preencherDadosTutor: true,
  }),
  mounted() {},
  methods: {
    gerarPdf() {
      this.$emit("gerarPDF", {
        doc: "atestado",
        tipo: this.tipo.toLowerCase(),
        preencherDadosTutor: this.preencherDadosTutor,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
    },
  },
};
</script>

<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
</style>
